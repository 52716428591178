.admin-donation-matching-browser {
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;

    .alert-dialog-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .alert-stay-button {
            padding-bottom: 0px;
        }
    }

    &-content {
        border-top: $border-line;
        min-height: 100%;
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    &-requests {
        border-right: $border-line;
        padding: 36px 4rem;
        flex: 6;
    }

    &-form-details {
        flex-grow: 1;
        padding: 36px 28px;
        flex: 4;
    }
}
