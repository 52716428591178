.request-group-scroll-window {
    height: 690px;
    width: 391px;
    overflow: auto;

    @include disable-scrollbar;
}

.request-group-list-item-wrapper {
    padding: 3px 0px;
    border-bottom: $border-line;

    &:last-child {
        border-bottom: unset;
    }
}
