.alert-dialog {
    display: flex;
    flex-direction: column;

    position: absolute;
    z-index: 1;
    border-radius: 4px;
    background-color: $alert-light-color;
    color: $alert-dark-color;
    padding: 10px 18px;

    .alert-display-text {
        font-weight: bold;
        margin-bottom: 0;
    }

    .alert-dialog-buttons {
        align-self: end;
        margin-top: 20px;
    }

    .alert-leave-button {
        display: inline-block;
        border-radius: 4px;
        border: none;
        background-color: $alert-dark-color;
        color: $white-text-color;
        padding: 6px 19px;
    }

    .alert-stay-button {
        display: inline-block;
        border-radius: 4px;
        border: none;
        background-color: $alert-light-color;
        color: $alert-dark-color;
        padding-right: 18px;
        padding-bottom: 10px;
    }

    .alert-prompt {
        margin-bottom: -5px;
    }
}
