.form-item {
    &-disabled {
        color: $disabled-text;
    }
    &-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &-instructions {
        color: $disabled-text;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        margin-top: 8px;
    }
    &-bottom {
        text-align: left;
        margin-top: 11px;
        display: flex;
        flex-direction: row;
    }
    &-error-text {
        color: $input-error;
        font-size: 12px;
        vertical-align: bottom;
        padding-top: 5px;
        text-align: right;
    }
    &-error-icon {
        margin-left: 10px;
        color: $input-error;
        font-size: 22px;
    }
}
