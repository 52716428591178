.send-reset-password-email-modal {
    &-instructions {
        width: $auth-modal-content-width;
        margin: auto;
        margin-bottom: 20px;
        text-align: left;
    }

    & button {
        width: $auth-modal-content-width;
        padding: 0px !important;
    }
}
