.request-type-list-item {
    border-bottom: $border-line;
    &.list-group-item,
    &.list-group-item:last-child {
        // overwrite React Bootstrap styling
        border-bottom: $border-line;
    }

    padding: 16px 0px;
    line-height: 20px;

    b {
        @include extra-bold-text;
    }

    #name {
        display: block;
        float: left;
    }

    #numOpen {
        display: block;
        float: right;
    }
}
