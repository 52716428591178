.circle-image {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    background-color: $missing-image-background;

    img {
        display: inline;
        margin: 0 auto;
        height: 100%;
        width: auto;
    }
}
