.edit-map-quote-card {
    border: 1px solid $tpc-green;
    border-radius: $box-border-radius;
    padding: 25px;
    width: 45%;
    display: flex;
    flex-direction: column;

    .form-fields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .form-fields-left {
        width: 18%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .circle-image {
            height: 90px;
            width: 90px;
        }

        h1 {
            font-size: 12px;
            line-height: 28px;
            color: $tpc-green;
            cursor: pointer;
        }

        .error-message {
            font-size: 12px;
            line-height: 12px;
            color: $error-color;
            text-align: center;
        }
    }

    .form-fields-right {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin-top: -6px;
    }

    .text-area-error {
        display: flex;
        flex-direction: row;
        margin: 5px 0 0 0;
        align-self: flex-end;

        .error-icon {
            font-size: 15px;
            color: $error-color;
        }

        h1 {
            margin: 0 0 0 5px;
            font-size: 12px;
            line-height: 24px;
            color: $error-color;
            align-self: flex-end;
        }
    }

    .text-area-label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h1 {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .text-area-container,
    .text-area {
        width: 100%;
        height: 100%;
    }

    .form-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        i {
            font-size: 25px;
            cursor: pointer;
        }
    }

    .form-footer-left {
        width: 50%;
    }

    .form-footer-right {
        width: 50%;
        display: flex;
        justify-content: space-between;
    }

    .cancel-button {
        @include grey-button;
        width: 46%;
    }

    .save-button {
        @include tpc-green-button;
        width: 48%;
    }
}

.preview-map-quote-card {
    border: $border-line;
    border-radius: $box-border-radius;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 17px;
    width: 45%;
    height: 161px;

    .card-right {
        width: 78%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-icons {
        display: flex;
        justify-content: flex-end;

        i.bi-trash {
            font-size: 25px;
            cursor: pointer;
        }

        i.bi-pencil {
            margin-left: 20px;
            font-size: 23px;
            cursor: pointer;
        }
    }
}
