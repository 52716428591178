.admin-request-group-list {
    width: 100%;

    .title {
        @include heavy-text;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;

        color: $dark-gray;

        flex: 1;
        padding-left: 95px;
    }

    .row {
        display: flex;
        justify-content: space-between;
    }

    .action-group {
        display: flex;
        flex-direction: row;
        align-content: flex-end;
        margin-right: 80px;
    }

    .action-group .spacing {
        width: 27px;
    }

    .button {
        @include normal-button;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: 160px;
        height: 40px;
        display: flex-end;

        background: $tpc-green;
        border-radius: 4px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.1px;

        color: white;
    }

    .btn,
    .btn:hover,
    .btn:focus,
    .btn:active,
    .show > .btn-primary.dropdown-toggle {
        background-color: $tpc-green;
        color: $white;
        height: 39px;
        width: 150px;
    }

    .admin-group-button {
        &-dropdown {
            background-color: $dropdown-color;
            border-radius: 4px;
            box-shadow: $dropdown-box-shadow;
            border: none;
            &-item {
                padding-top: 15px;
            }
        }

        .dropdown-menu {
            margin-top: 8px;
        }
    }
    .page-navigation {
        display: block;
        float: right;
        margin-right: 69px;
        margin-top: 24px;
        margin-bottom: 21px;
    }

    .spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        height: 100vh;
        width: 100vw;

        .spinner-border {
            width: 40px;
            height: 40px;
        }
    }
}
