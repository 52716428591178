.donation-matching-request-table {
    @include table-base;
    table-layout: fixed;
    width: 100%;

    thead th {
        padding: 16px 16px;
    }

    td {
        padding: 1.1rem;
        vertical-align: middle;
    }

    tbody tr {
        @include table-text();
        @include normal-text();

        .semibold {
            @include semibold-text();
        }

        .hidden {
            color: #a5a5a5;
        }

        .item-quantity-display {
            .text-field .input-container {
                width: 48px;
                height: 40px;
            }

            .text-field-input {
                border: 0.4px solid #dcdcdc;
                border-radius: 4px;
                padding-left: 0;
                text-align: center;
            }
        }

        .item-quantity-selection {
            display: flex;
            align-items: center;

            &.error {
                margin-left: -34px;
            }

            .alert-icon {
                font-size: 24px;
                color: $input-error;
                margin-right: 12px;
            }

            .text-field-input {
                width: 48px;
                height: 40px;
                border-color: #dcdcdc;
                &.error {
                    border-color: $input-error;
                }
            }

            .text-field i {
                margin-left: -18px;
                font-size: 12px;
                color: $scroll-bar-color;

                &.error {
                    color: $input-error;
                }
            }

            .scrollable-dropdown .scroll-window {
                display: flex;
                flex-direction: column;

                padding: 8px 12px;
                margin-top: 5px;

                span {
                    margin-top: 8px;
                    margin-bottom: 8px;
                    cursor: pointer;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
