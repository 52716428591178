.text-field-with-action {
    @include text();
    & .text-field-input {
        width: 100%;
        height: 40px;
    }
    .text-field i {
        margin-left: -30px;
    }
    .action {
        margin-top: 10px;
        display: inline-flex;
        &.error {
            border: 1px solid $error-faded-color;
        }
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 19px;
        font-size: 12px;
    }
    .action-string {
        margin-right: 10px;
    }
    .action-value {
        flex-grow: 1;
    }
    .action-value-text {
        background-color: $tag-background;
        padding: 5px;
        border-radius: 4px;
    }
    & i {
        font-size: 16px;
    }
    & i.bi.bi-arrow-return-left::before {
        vertical-align: unset;
    }
}
