.tooltip-inner {
    @include normal-text;
    padding: 10px 20px;
    background-color: $tooltip-background-color;
    color: $tooltip-text-color;
    text-align: left;
}

.tooltip {
    transition: opacity 0.15s linear;

    &.show {
        opacity: 1;
    }
}

.bs-tooltip-right .arrow::before {
    border-right-color: $tooltip-background-color;
}

.tooltip-trigger {
    padding: 5px 10px;
    color: $tooltip-trigger-color;
}
