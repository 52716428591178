.request-group-browser {
    .request-group-header {
        display: flex;

        .request-group-description {
            flex: 1;
            margin-left: 160px;

            .request-group-title {
                @include heavy-text;
            }
        }

        .buttonDiv {
            position: relative;
        }

        .btn,
        .btn:hover,
        .btn:focus,
        .btn:active,
        .show > .btn-primary.dropdown-toggle {
            background-color: $admin-request-group-browser-button-color;
            color: $tpc-green;
            border-color: $tpc-green;
            height: 36px;
            width: 60px;
        }
    }

    .request-group-button {
        text-align: right;

        &-dropdown {
            background-color: $dropdown-color;
            border-radius: 4px;
            box-shadow: $dropdown-box-shadow;
            border: none;
            padding: 0;

            &-item {
                padding: 14px 20px;
            }
        }

        .dropdown-menu {
            margin-top: 8px;
        }
    }

    .spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        height: 100vh;
        width: 100vw;

        .spinner-border {
            width: 40px;
            height: 40px;
        }
    }
}
