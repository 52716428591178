.richtext-field {
    width: 484px;
    height: 252px;
    overflow: hidden;

    background-color: $background;
    border: 1px solid $dark-border-color;
    box-sizing: border-box;
    border-radius: 4px;

    &.error {
        border-color: $error-color;
    }
}

.richtext-field-controls {
    width: 100%;
    border-bottom: $window-border;

    .error & {
        border-color: $error-color;
    }

    button {
        width: 32px;
        height: 32px;
        background-color: transparent;
        border: none;
        border-right: $window-border;

        i {
            width: 12px;
            height: 12px;
        }
    }
}

.richtext-field-input {
    height: 220px;
    max-height: 220px;
    overflow-y: hidden;

    .richtext-default-text {
        @include normal-text;
        position: absolute;
        font-size: 14px;
        line-height: 21px;
        color: $grey-text-color;
        padding: 12px 22px 0 22px;
        color: $dark-border-color;
    }

    .scroll-window {
        border: none;
    }

    .public-DraftEditor-content {
        @include normal-text;
        font-size: 14px;
        line-height: 21px;
        color: $grey-text-color;
        padding: 12px 22px 0 22px;

        .error & {
            @include error-text;
        }
    }

    .DraftEditor-editorContainer {
        border: none;
    }
}
