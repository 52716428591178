.donation-form-progress-stepper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .step {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .active-step:hover {
        cursor: pointer;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .disabled-step {
        opacity: 0.15;
    }

    .step-label {
        @include semibold-text;
        font-size: 12px;
        line-height: 18px;
        margin-top: 8px;
    }
}
