.edit-testimonial-card {
    border-radius: 5px;
    border: $window-border;
    padding: 50px;
    display: flex;
    flex-direction: row;

    .image-upload {
        border-radius: 5px;
        width: fit-content;
        position: relative;
        cursor: pointer;

        .base {
            width: 382px;
            height: 236px;
            object-fit: cover;
            position: relative;
            z-index: 1;
        }

        .overlay {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 382px;
            height: 236px;
            text-align: center;
            z-index: 2;

            &-text {
                @include white-text;
                @include semibold-text;
            }
        }
    }

    .error-text {
        @include error-text;
        @include small-text;
        color: $error-color;
    }

    .text-and-submission {
        margin-left: 50px;
        flex: 1;
        text-align: right;

        .text-area {
            resize: none;
            min-height: 150px;
            padding: 10px;
        }

        .buttons {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 50px;

            .cancel-button {
                @include grey-button;
                @include semibold-text;
                margin-left: 20px;
                width: 132px;
            }

            .update-button {
                @include tpc-green-button;
                @include semibold-text;
                margin-left: 20px;
                width: 132px;
            }

            i {
                margin: auto;
                margin-right: 0;
                font-size: 24px;
                color: $grey-text-color;
            }
        }
    }
}
