.tag-input {
    .tag-list {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
    }
    .deletable-tag {
        margin-top: 5px;
        margin-right: 10px;
    }
}
