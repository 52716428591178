.request-table {
    @include table-base;
    table-layout: fixed;
    width: 100%;

    h1 {
        @include section-header-text;
        padding-bottom: 4px;
        margin: 0;
    }
    .form-check-input {
        cursor: pointer;
    }
    .edit {
        color: #333333;
        cursor: pointer;
    }
    .delete {
        color: #333333;
        cursor: pointer;
    }
    & td {
        border-top: unset;
        padding: 0.6rem;
        vertical-align: unset;
    }
    .btn-cont {
        display: flex;
        justify-content: flex-end;
        margin-left: 20px;
        margin-right: 20px;
    }
}

.request-table-empty-message {
    margin-top: 20px;
}
