.edit-map-quotes-section {
    margin-top: 12px;

    .section-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
            @include heavy-text;
            font-size: 20px;
            line-height: 24px;
        }

        .add-map-quotes {
            font-size: 20px;
            line-height: 24px;
            color: $tpc-green;
            cursor: pointer;
        }
    }

    .map-quotes-counter {
        margin-top: 20px;
        @include normal-text;
        font-size: 16px;
        line-height: 16px;
    }

    .cards {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 30px;
    }

    .edit-map-quote-card,
    .preview-map-quote-card {
        width: 48%;
        margin-bottom: 36px;
    }
}
