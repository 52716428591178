$request-group-form-panel-width: 482px;

.request-group-form {
    .modal-dialog {
        max-width: 1100px;
    }

    .modal-content {
        width: 100%;
    }

    .modal-body {
        padding: 0px 44px 36px 44px;
    }

    form {
        width: 100%;
    }

    &-fields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & .form-item {
            margin-top: 31px;
        }
    }

    .form-modal-buttons {
        width: $request-group-form-panel-width;
        align-self: flex-end;
    }

    .text-field-input {
        width: $request-group-form-panel-width;
        height: 45px;
    }

    .text-field-form-item {
        .form-item-top {
            width: $request-group-form-panel-width;
        }
        .form-item-error-icon {
            margin-top: 8px;
        }
    }

    .tag-input-form-item {
        .tag-input {
            width: $request-group-form-panel-width;
        }
        .form-item-top {
            width: $request-group-form-panel-width;
        }
        .action {
            width: $request-group-form-panel-width;
        }
        .form-item-error-icon {
            margin-top: 5px;
        }
    }

    .imagepicker-form-item {
        width: $request-group-form-panel-width;
        .imagepicker-preview {
            height: 351px;
            width: $request-group-form-panel-width;
        }

        .form-item-top {
            width: $request-group-form-panel-width;
        }

        .form-item-error-icon {
            margin-right: -40px;
        }

        .image-list-container {
            height: 162px;
            width: $request-group-form-panel-width;

            img {
                width: 105px;
                height: 76px;
            }

            .img-wrapper {
                margin-bottom: 10px;
            }

            .img-wrapper-overlay {
                width: 105px;
                height: 76px;
            }
        }
    }

    .richtext-field-form-item {
        & .richtext-field {
            width: 484px;
            height: 252px;
        }

        .form-item-top {
            width: 484px;
        }
    }

    &-footer {
        & .button {
            float: right;
            padding: 12px 33px;

            background-color: $tpc-green;
            &:hover {
                background-color: $tpc-green-hover;
                color: $white-text-color;
            }
        }
    }

    .alert-dialog {
        align-self: center;
        margin-top: -30px;
        width: 500px;
    }

    &-loading-content {
        min-height: 650px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .spinner {
        align-self: center;
    }
}
