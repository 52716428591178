$active-carousel-indicator: #ababab;
$inactive-carousel-indicator: #dcd6d6;

.testimonials-carousel {
    max-width: 1320px;
    border-radius: 20px;
    box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.09) !important;

    .carousel {
        padding: 48px;

        &-item {
            margin-bottom: 57px;

            img {
                border-radius: 10px;
                width: 100%;
                height: 364px;
                object-fit: cover;
            }

            p {
                font-size: 24px;
                font-style: italic;
                font-weight: 400;
                margin-left: 56px;

                @media (max-width: 978px) {
                    margin-top: 56px;
                    margin-left: 0;
                }
            }
            @media (max-width: 978px) {
                margin-bottom: 32px;
            }
        }

        &-indicators li {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: $inactive-carousel-indicator;
            margin-right: 18px;
        }

        &-indicators li :active {
            background-color: $active-carousel-indicator;
        }
    }
}
