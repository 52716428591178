.donation-form-item-details-page {
    .add-item-trigger {
        @include tpc-green-text;
        @include semibold-text;
        margin-left: 10px;
        margin-top: 22px;
        cursor: pointer;
    }

    .horizontal-divider-line,
    .donation-item-card,
    .donation-item-form {
        width: 100%;
        margin-bottom: 40px;
    }

    .donation-form-page-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 5%;
    }

    .form-counter {
        @include tpc-green-text;
        @include bold-text;
        font-size: 16px;
    }

    .nav-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .dropdown .scroll-window {
        max-height: 250px;
    }
}
