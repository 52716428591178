.donation-form-contact-info-page {
    .contact-info-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

    .form-item {
        width: 47%;
        margin-bottom: 40px;
    }

    .text-field {
        width: 100%;

        .input-container {
            height: 45px;
        }
    }
}
