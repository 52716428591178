.donor-request-group-browser {
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;

    &-header {
        @include donor-homepage-section-header-text;
        text-align: center;
    }

    &-info {
        @include table-text;
        align-self: flex-start;
    }

    &-content {
        margin-top: 20px;
        border-top: $border-line;
        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    &-list {
        border-right: $border-line;
        min-width: 423px;
    }

    &-indiv-view {
        padding-top: 32px;
        flex-grow: 1;
    }

    .search-bar-container {
        width: 704px;
        height: 48px;

        .search-bar {
            padding-left: 20px;
            font-size: 16px;
        }

        .search-bar-icon-container {
            padding-right: 16px;
        }
    }
}
