.text-area-container {
    display: flex;
    flex-direction: column;

    .text-area-label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h1 {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .text-area {
        border-radius: $box-border-radius;
        border-color: $textfield-input-border;
        padding-left: 12px;
        padding-top: 8px;

        &.error {
            border-color: $error-color;
        }

        &:focus {
            outline-width: 0;
            border-color: $tpc-green;
        }
    }
}
