.admin-unmatched-donation-forms-page {
    .admin-page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .page-header {
        display: flex;
        flex-direction: row;
        padding: 3% 6%;
        width: 100%;
    }

    .link {
        @include heavy-text;
        font-size: 24px;
        line-height: 32px;
        color: $grey-text-color;
        opacity: 0.2;
        padding: 0;
        margin-right: 1.5%;
    }

    .active {
        opacity: 1;
    }
}
