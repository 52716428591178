.map-with-markers {
    position: relative;

    .map-base {
        position: relative;
        height: 509px;
        z-index: 2;
    }

    .marker-overlay {
        width: 100%;
        height: 509px;
        z-index: 3;
        position: absolute;
    }

    .circle-image {
        position: absolute;

        img {
            object-fit: cover;
        }
    }
}
