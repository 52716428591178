@import "config";
@import "../components/atoms/style/index";
@import "../components/molecules/style/index";
@import "../components/organisms/style/index";
@import "../components/layouts/style/index";
@import "../pages/style/index";

body {
    @include normal-text;
    // prevent wrapping from hyphenating words
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

#root > div {
    min-height: 100vh;
}

img {
    border-radius: $box-border-radius;
}
