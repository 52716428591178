.confirm-donation-form-approval-dialog {
    .modal-dialog {
        width: 35%;
    }

    .modal-header {
        border: 0;
    }

    .modal-header button.close {
        padding: 0;
        margin: 0;
    }

    .modal-body {
        padding: 0px 32px 22px 32px;
    }

    .form-modal-buttons {
        width: 60%;
        margin: 0;
        align-self: flex-end;

        .form-modal-cancel-button,
        .form-modal-submit-button {
            width: 48%;
        }
    }
}
