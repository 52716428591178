.step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border: 1px solid $stepper-border-color;
    border-radius: 2px;

    h3 {
        margin-bottom: 0;
        font-size: 16px;
        @include light-text;
    }

    &.selected-step h3 {
        @include tpc-green-text;
        @include heavy-text;
    }
}
