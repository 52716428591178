.donation-edit-form {
    .modal-dialog {
        max-width: 25%;
    }

    .modal-body {
        padding: 0 6% 40px 6%;
    }

    form {
        width: 100%;
    }

    .form-item {
        width: 100%;
        margin-top: 16px;
    }

    .form-item-error-icon {
        margin-left: -30px;
    }

    .form-item-top {
        width: 100%;
    }

    .text-field {
        width: 100%;

        .input-container {
            height: 45px;
        }
    }

    .text-area-container {
        width: 100%;
        min-height: 94px;
    }

    .form-modal-buttons {
        align-self: flex-end;
        width: 60%;

        .form-modal-cancel-button,
        .form-modal-submit-button {
            width: 48%;
        }
    }
}
