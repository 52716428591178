.imagepicker {
    &-title {
        margin-bottom: 11px;
    }
    &-hidden {
        display: none;
    }
    &-slider {
        margin-top: 5px;
        width: 82%;
        float: left;
    }
    &-minus {
        float: left;
    }
    &-plus {
        float: left;
    }
    &-trash {
        position: absolute;
        bottom: 2%;
        right: 2%;
        z-index: 1;
    }
    &-upload {
        &-top-text {
            text-align: center;
            font-size: 20px;
        }
        &-bottom-text {
            text-align: center;
            font-size: 14px;
        }
        &-icon {
            text-align: center;
            font-size: 60px;
        }
    }
    &-dropzone {
        height: 100%;
        width: 100%;
        padding-top: 20%;
    }
    .alert-icon {
        color: $input-error;
        position: absolute;
        font-size: 22px;
        margin: 0px 0px 0px 370px;
    }
    .toprow {
        width: 350px;
        margin-left: 8px;
        display: "flex";
        flex-direction: row;
        justify-content: space-between;

        .text-error {
            color: $input-error;
            font-size: 12px;
        }
    }

    &-preview {
        position: relative;
        height: 248px;
        width: 348px;
        display: flex;

        font-size: 24px;

        img {
            height: inherit;
            width: inherit;
            object-fit: cover;
        }
        .unselected {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $disabled-text;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px dashed $disabled-text;
        }

        .error {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $faint-input-error;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px dashed $input-error;
        }
    }
}

.MuiSlider-rail,
.MuiSlider-track {
    height: 5px !important;
    bottom: 11px !important;
    border-radius: 10px !important;
}
