.upload-image-modal {
    form,
    .imagepicker,
    .imagepicker-preview {
        width: 100%;
    }

    .imagepicker-dropzone {
        padding-top: 30px;
    }

    .imagepicker-crop-controls {
        display: flex;
    }

    .form-item-bottom {
        display: flex;
        flex-direction: column;
    }
}
