.search-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 514px;
    height: 40px;

    border: 1px solid $searchbar-border;
    border-radius: 4px;

    .search-bar {
        flex: 1;
        padding-left: 12px;
        border: none;
        outline: none;
        caret-color: #b7b7b7;
        font-size: 16px;

        &::placeholder {
            color: #b7b7b7;
        }

        &:focus::placeholder {
            color: transparent;
        }
    }

    .search-bar-icon-container {
        padding-left: 8px;
        padding-right: 12px;
        color: #9c9c9c;

        .search-bar-icon-default {
            font-size: 18px;
        }

        .search-bar-icon-cancel {
            font-size: 24px;
        }
    }
}
