.simple-page-navigation {
    @include normal-text;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;

    b {
        @include extra-bold-text;
    }

    .page-number-disabled {
        visibility: hidden;
    }

    .page-arrow {
        display: inline-block;
        width: 11px;
        height: 11px;
        border: 2px solid $page-arrow-color;
        border-width: 0 3px 3px 0;
        border-radius: 0 0.2rem;
        cursor: pointer;

        &.disabled {
            border-color: $page-arrow-disabled-color;
            cursor: default;
        }
    }

    .prev-page-arrow {
        padding-left: 16px;
        .page-arrow {
            transform: rotate(135deg);
        }
    }

    .next-page-arrow {
        padding-left: 18px;
        .page-arrow {
            transform: rotate(-45deg);
        }
    }
}
