.logo-modal {
    align-items: center;
    text-align: center;

    .modal-header {
        border-bottom: unset;
    }

    .button {
        color: #ffffff;
        background-color: $tpc-green;
        border-radius: 4px;
        height: 48px;
        padding: 5px 120px;
        margin: 15px 8px;

        &:hover {
            background-color: $tpc-green-hover;
        }
    }

    .button.signup {
        width: $auth-modal-content-width;
        margin: 38px 8px 15px 8px;
    }

    .button.reset-pass {
        width: 385px;
        margin: 16px 8px 15px 8px;
    }

    .row {
        display: flex;
        margin-left: 0px;
        flex-direction: row;
        justify-content: space-between;
    }

    .row.bordered {
        display: flex;
        margin: 0px 40px;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid #e9e9e9;
        border-radius: 4px !important;
    }
    .row.bordered.error {
        border-color: $input-error;
    }

    .text {
        margin: 20px 0px;
    }

    .text.signup {
        margin-bottom: 10px;
        margin-left: 40px;
        text-align: left;
    }

    .text.signup {
        margin-bottom: 10px;
        text-align: left;
    }

    .text.error {
        text-align: right;
        vertical-align: bottom;
        line-height: 250%;
        color: $input-error;
        font-size: 12px;
        margin-right: 55px;
        margin-bottom: 0px;
    }

    .text.redirect {
        color: $faded-modal-text-color;
        font-size: 14px;
        cursor: pointer;
        margin: 10px 0px 0px 0px;
    }

    .text.redirect.center {
        text-align: center;
    }

    .text.redirect.right {
        text-align: right;
        margin-right: 40px;
    }

    .pc-icon {
        margin-bottom: 20px;
    }

    .title {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .subtitle {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 40px;
    }

    .link {
        color: $tpc-green;
    }

    .no-account {
        color: $faded-modal-text-color;
    }

    .text-field-input {
        width: $auth-modal-content-width;
        height: 48.96px;
    }

    .text-field-input.password {
        width: 325px;
    }

    .text-field-input-alert {
        @include input-icon;
        width: 40px;
        color: $input-error;
        font-size: 24px;
        text-align: center;
        margin: 7px 7px 0px 0px;
        border: none;
    }
    .hidden {
        display: none;
    }
    .text-field-input-btn {
        @include input-icon;
        width: 40px;
        font-size: 24px;
        text-align: center;
        border: none;
        margin: 2px 8px 0px 0px;
        cursor: pointer;
    }

    .text-field-input.password.error {
        width: 325px;
    }

    .pass-req {
        text-align: center;
    }
}
