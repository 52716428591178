.navbar {
    height: 100px;
    padding-left: 80px;
    @media (max-width: 992px) {
        padding-left: 50px;
    }
    padding-right: 45px;
    display: flex;
    border-bottom: $border-line;
}

.logo {
    width: 210.24px;
    height: 48px;
}

.navbar-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .nav-link {
        @include light-text;
        padding: 0;
        margin: 0 25px;

        color: inherit;
        font-size: 16px;
        line-height: 19px;
        display:flex;
        align-items: center;
    }

    .left,
    .right {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .active {
        @include heavy-text;
    }
}
