.sign-in-modal {
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .button.signin {
        width: $auth-modal-content-width;
        margin: 30px 8px 14px 8px;
    }

    .form-item {
        width: $auth-modal-content-width;
    }

    .password-form-item {
        margin-top: 24px;
        .text-field i {
            font-size: 20px;
            margin-left: -30px;
        }
    }

    .text.redirect.right {
        align-self: flex-end;
    }
}
