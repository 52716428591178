.text-field {
    & > * {
        vertical-align: middle;
    }

    &-input {
        align-self: left;
        border: 1px solid $dark-border-color;
        box-sizing: border-box;
        border-radius: 4px;
        padding-left: 12px;
        line-height: 0;
        width: 100%;
        height: 100%;

        &:focus {
            outline-width: 0;
            border-color: $tpc-green;
        }

        &:disabled,
        &.disabled {
            border-color: $disabled-input-border-color;
            background: none;
            &::placeholder {
                color: $disabled-text;
            }
        }

        &.error {
            border: 1px solid $input-error;
            &.red-error-text {
                color: $faint-input-error;
                &::placeholder {
                    color: $faint-input-error;
                }
            }
        }
    }

    & i {
        margin-left: -20px;
        font-size: 10px;
        color: $soft-black;

        &.disabled {
            color: $input-icon-color;
        }

        &.error {
            color: $input-error;
        }
    }

    .char-count-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        p {
            margin: 4px 0 0 0;
        }
    }
}
