.donation-form-confirmation-page .donation-form-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        @include heavy-text;
        font-size: 30px;
        margin-bottom: 50px;
    }

    p {
        @include semibold-text;
        font-size: 18px;
        text-align: center;
        margin-bottom: 25px;
    }

    .button {
        @include tpc-green-button;
        width: 50%;
        margin-top: 50px;
    }
}
