.testimonial-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 357px;
    padding: 25px;
    border: $window-border;
    border-radius: 5px;
    margin-right: 72px;
    margin-bottom: 50px;

    img {
        width: 310px;
        height: 190px;
        object-fit: cover;
        margin-bottom: 25px;
    }

    .text {
        max-width: 310px;
    }

    .action-icons {
        margin-top: auto;
        display: flex;
        flex-direction: row-reverse;
        i {
            margin-left: 16px;
            font-size: 25px;
            color: $grey-text-color;
        }
    }
}
