.request-group-table {
    @include table-base;

    table {
        table-layout: fixed;
        width: 100%;
        overflow-x: auto;
    }

    thead tr th {
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
    }

    thead th,
    td {
        padding-left: 40px;
        padding-right: 0px;
    }

    th:nth-child(2),
    td:nth-child(2) {
        padding-left: 16px;
    }

    th:nth-last-child(2),
    td:nth-last-child(2) {
        padding-right: 16px;
    }

    td {
        word-wrap: break-word;
        height: 90px;
        vertical-align: middle;
    }

    tr td:nth-child(2) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        -moz-border-radius-topleft: 4px;
        -moz-border-radius-bottomleft: 4px;
        -webkit-border-top-left-radius: 4px;
        -webkit-border-bottom-left-radius: 4px;
    }

    tr td:last-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        -moz-border-radius-topright: 4px;
        -moz-border-radius-bottomright: 4px;
        -webkit-border-top-right-radius: 4px;
        -webkit-border-bottom-right-radius: 4px;
    }

    .data-row:hover {
        td:not(.spacing-col) {
            background-color: $selected-item-background;
        }
    }

    .name-col {
        width: 222px;
        overflow: hidden;
        word-wrap: break-word;
    }
    .numOpen-col {
        width: 146px;
        overflow: hidden;
        word-wrap: break-word;
    }
    .types-col {
        width: 370px;
        overflow: hidden;
        word-wrap: break-word;
    }
    .nextRecipient-col {
        width: 272px;
        overflow: hidden;
        word-wrap: break-word;
    }
    .date-col {
        width: 178px;
        overflow: hidden;
        word-wrap: break-word;
    }
    .image-col {
        width: 136px;
        overflow: hidden;
        word-wrap: break-word;
    }

    .spacing-row {
        td {
            height: 0px;
            padding: 5px 0 0 0;
        }
    }

    .border-row {
        td:nth-child(2) {
            padding-left: 0;
        }

        td {
            height: 0px;
            padding: 5px 0;

            .border-line {
                height: 0px;
                border-bottom: 1px solid #e0e0e0;
            }
        }
    }

    .requestType-tag-list {
        display: flex;
        flex-wrap: wrap;

        .requestType-tag-list-item {
            padding: 3px;
        }
    }

    .img-wrapper {
        width: 80px;
        height: 59px;
        border-radius: $box-border-radius;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .no-groups-msg {
        margin-left: 80px;

        .create-group-link {
            @include semibold-text;
            color: $tpc-green;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        height: 100vh;
        width: 100vw;

        .spinner-border {
            width: 40px;
            height: 40px;
        }
    }
}
