.warning-dialog {
    display: block;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 37%;
    width: 29%;
    border-radius: 4px;
    background-color: $alert-light-color;
    color: $alert-dark-color;
    padding: 10px 18px;

    .warning-display-text {
        font-weight: bold;
        margin-bottom: 0;
    }

    .warning-dialog-buttons {
        margin-top: 2%;
        margin-left: 80%;
    }

    .warning-leave-button {
        display: inline-block;
        border-radius: 4px;
        border: none;
        background-color: $alert-dark-color;
        color: $white-text-color;
        padding: 6px 19px;
    }

    .warning-prompt {
        margin-bottom: -5px;
    }
}
