.request-group-view {
    display: block;
    width: 100%;
    width: 50vw;
    max-width: 908px;
    margin-left: 32px;
    margin-right: 0;

    .spinner {
        display: block;
        width: 100vw;
        padding-left: 50%;
        padding-top: 40%;

        .spinner-border {
            width: 40px;
            height: 40px;
        }
    }

    .panel {
        display: flex;
        width: 100%;
    }

    .section {
        display: flex;
        align-items: baseline;
        flex-direction: row;
        padding: 0;

        &#left {
            display: block;
            flex: 1;

            .request-type-list {
                max-width: 514px;
            }
        }
        &#right {
            display: flex;
            align-items: baseline;
            flex-direction: column;
            margin: 0;
            margin-left: 32px;

            .infoBox {
                width: 18vw;
                margin: 0;
                max-width: 331px;
            }

            .infoBox:last-child {
                flex: 1;
            }
        }
    }

    #header {
        @include heavy-text;
        margin-bottom: 5px;
        font-size: 24px;
        line-height: 29px;
    }
    #date-updated {
        @include alt-faded-text;
        margin-bottom: 0px;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
    }

    #image {
        padding: 16px 0px 24px;
        max-width: 513px;
        height: 365px;
        border-radius: $box-border-radius;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    #description {
        padding-top: 20px;

        .card-text {
            padding-top: 8px;
        }
    }

    & .button {
        @include tpc-green-button;

        &:hover,
        &.alt-button {
            @include alt-button;
        }
    }
}
