.admin-homepage {
    padding: 0;
    .navbar {
        margin-bottom: 40px;
    }
}

.admin-request-groups-page {
    .navbar {
        margin-bottom: 40px;
    }
}
